import { AuthType } from '@enums/auth-type.enum';
import { UserRole } from '@enums/user-role.enum';
import { Address } from './address.model';
import { UserFlag } from '@enums/user-flag.enum';

/**
 * Represents a user in the system.
 */
export class User {
  /**
   * The activation status of the user.
   */
  activated?: number;

  /**
   * The addresses associated with the user.
   */
  addresses?: Address[];

  /**
   * The email address of the user.
   */
  email?: string;

  /**
   * The full name of the user.
   */
  fullName?: string;

  /**
   * The authentication type of the user.
   */
  auth_type?: AuthType;

  /**
   * The ID of the user.
   */
  id?: number;

  /**
   * The login username of the user.
   */
  login?: string;

  /**
   * The phone number of the user.
   */
  phone?: string;

  /**
   * The points ratio of the user.
   */
  pointsRatio?: number;

  /**
   * The ID of the user who referred this user.
   */
  referredUserId?: number | null;

  /**
   * The roles assigned to the user.
   */
  roles?: UserRole[];

  /**
   * The wallet balance of the user.
   */
  walletBalance?: string;

  /**
   * The ID of the associated restaurant.
   */
  resId?: number | null;

  isTester?: UserFlag;

  birth_date?: string;

  /**
   * Creates a new instance of the User class.
   * @param data - The data to initialize the user with.
   */
  constructor(data: User) {
    Object.assign(this, data);
  }
}
